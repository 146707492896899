import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import Headline from '../../components/Headline/Headline';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import styles from '../../components/BlogTeaser/BlogTeaser.module.scss';
import image1 from '../../images/corona_bild.png';

const AktuellInhaltPage = () => (
  <Layout headerWhite>
    <SEO title="Aktuelles" />
    <Section>
      <Container>
        <Headline subtitle="Praxis für Orthopädie">Coronavirus</Headline>
        <Row className="justify-content-md-center">
            <Col xs="12" md="10">
            <img src={image1} style={{margin: '0 auto 1.45rem', display: 'block' }} alt="Kostka" />
            <p>
            Liebe Patientinnen und Patienten, 
            <br/>
            <br/>
            aufgrund des derzeitigen Infektionsgeschehens halten wir uns an die von der Bundesregierung und des RKI geforderten Hygiene-Maßnahmen und Regeln, um Ihnen einen bestmöglichen Schutz zu bieten. Dieses umfasst im Einzelnen
            <br/>
            <br/>
            Desinfektionsmittel-Spender am Praxiseingang 
            Sprechstundenplanung ohne wesentliche Wartezeiten
            Regelmäßiges Lüften
            häufiges Desinfizieren aller Oberflächen
            Ausrüstung aller Mitarbeiter mit FFP2 Masken
            Berücksichtigung der Abstandsregeln im Wartebereich und in allen Zimmern
            <br/>
            <br/>
            Wir möchten Sie bitten, bei Erkältungssymptomen vom Praxisbesuch abzusehen und danken Ihnen für Ihr Verständnis in dieser besonderen Situation.
            <br/>
            <br/>
            Wir freuen uns auf Ihren Besuch.
            <br/>
            <br/>
            Ihr Praxisteam
            </p>
            </Col>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default AktuellInhaltPage;
